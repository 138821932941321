<template>
  <div class="container admin-profile-container">
    <alert v-if="Profile.user == null" class="shadow-card" />
    <div class="header mb-3 mp-print-0" v-if="Profile.user != null">
      <div class="header-body mp-print-0">
        <div class="row noPrint">
          <div class="col">
            <h6 class="header-pretitle">Admin</h6>
            <h1 class="header-title">
              {{ Profile.user.last }}, {{ Profile.user.first
              }}<span class="ml-3 h3">- ID {{ Profile.user.id }}</span>
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <admin-profile-navbar />
          </div>
        </div>
      </div>
    </div>
    <router-view v-if="Profile.user != null" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { default as AdminProfileNavbar } from "./ProfileNavbar";
export default {
  name: "AdminProfile",
  components: {
    AdminProfileNavbar,
  },
  computed: {
    ...mapState(["Profile"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.$store.dispatch("Profile/get");
  },
};
</script>
<style>
@media (min-width: 1024px) {
  .admin-profile-container .profile-container {
    margin-left: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
.admin-profile-container .profile-title {
  display: none;
}
.admin-profile-container .timeline-add-container,
.admin-profile-container .timeline-table-container {
  margin: 0 !important;
  padding: 0 !important;
}
.admin-profile-container .timeline-container,
.admin-profile-container .professions-container {
  margin-top: 2.25rem !important;
}
.admin-profile-container .admin-documents-container {
  margin-top: 1.5rem !important;
}
</style>
