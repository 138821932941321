<template>
  <div>
    <ul class="nav nav-tabs header-tabs noPrint profile-nav-container">
      <li
        v-for="link in nav"
        :key="link.to || link.action"
        :class="link.dropdown ? `nav-item dropdown` : `nav-item`"
      >
        <a
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          v-if="link.dropdown"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </a>
        <div class="dropdown-menu" v-if="link.dropdown">
          <router-link
            v-for="drop in link.dropdown"
            :key="drop.label"
            :to="getLink(drop.to)"
            class="dropdown-item"
            :class="name == drop.to ? `active` : ``"
          >
            <icon :type="drop.icon" class="mr-1" />
            {{ drop.label }}
          </router-link>
        </div>

        <!-- Single -->
        <router-link
          v-if="!link.dropdown && !link.action"
          :to="getLink(link.to, link.query)"
          class="nav-link"
          :class="
            name == link.to || (link.subLinks && link.subLinks.includes(name))
              ? `active`
              : ``
          "
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </router-link>
        <a
          v-if="!link.dropdown && link.action"
          href="/"
          @click="openCalendarModal"
          class="nav-link calendar-item-link"
        >
          <icon :type="link.icon" class="mr-1" />
          {{ link.label }}
        </a>
      </li>
    </ul>
    <sidebar
      :toggle="modalOpened"
      @close="closeCalendarModal"
      :title="`${user.last}, ${user.first} Events`"
      wrapClass="calendar-tray"
      class="calendar-modal"
    >
      <Calendar
        :key="Math.random()"
        :profileAdminUserId="user.id"
        @closeModal="modalOpened = false"
        v-if="modalOpened"
      ></Calendar>
    </sidebar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Calendar from "../../appointment/calendar";

export default {
  name: "AdminProfileNavbar",
  components: {
    Calendar,
  },
  data() {
    return {
      modalOpened: false,
      nav: [
        {
          label: "Profile",
          to: "admin.profile.basic",
          icon: "home",
          subLinks: [
            "admin.profile.basic",
            "admin.profile.address",
            "admin.profile.phones",
          ],
        },
        {
          label: "Documents",
          to: "admin.profile.documents",
          query: {
            src: "admin",
          },
          icon: "folder-open",
        },
        {
          label: "Timeline",
          to: "admin.profile.timeline",
          icon: "file",
        },
        {
          id: "payroll",
          label: "Pay Details",
          to: "admin.profile.payroll",
          icon: "money-check",
        },
        {
          label: "Professions",
          to: "admin.profile.professions",
          icon: "user-injured",
        },
        {
          label: "Shared Recordings",
          to: "admin.profile.shared-recordings",
          icon: "camera",
        },
        {
          label: "Availability",
          to: "admin.profile.availability",
          icon: "user-clock",
        },
        {
          label: "Events",
          action: "openCalendarModal",
          icon: "calendar",
        },
        {
          label: "Integration",
          to: "admin.c3.integration",
          icon: "anchor",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    name: function () {
      return this.$route.name;
    },
  },
  methods: {
    getLink(to, query = undefined) {
      if (query) {
        return {
          name: to,
          query,
        };
      }
      return {
        name: to,
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name && !from.query.event && to.query.event) {
        this.$router.replace({ query: "" });
        setTimeout(() => {
          this.modalOpened = true;
        }, 0);
      }
    },
  },
  mounted() {
    if (this.$route.query.event) {
      this.$router.replace({ query: "" });
      setTimeout(() => {
        this.modalOpened = true;
      }, 0);
    }
  },
};
</script>
